import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import SignaturePad from "signature_pad";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";

const SignatureModal = () => {
  const [file, setFile] = useState<File | null>();
  const [fileurl, setUrl] = useState("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const textContainerRef = useRef<HTMLDivElement>(null);

  const imgarray = [
    {
      name: "invoices.pdf",
      type: "pdf",
      size: "50kb",
      lastModified: "19 Aug 2024, 5:20 pm",
      fileGuid: "asdr23454esdf1",
    },
    {
      name: "payslips.pdf",
      type: "pdf",
      size: "112kb",
      lastModified: "22 Sep 2024, 10:30 am",
      fileGuid: "asdr23454esdf2",
    },
    {
      name: "AOA.docx",
      type: "docx",
      size: "79kb",
      lastModified: "24 Jun 2024, 11:30 am",
      fileGuid: "asdr23454esdf3",
    },
    {
      name: "License.jpeg",
      type: "jpeg",
      size: "33kb",
      lastModified: "21 Feb 2024, 2:40 pm",
      fileGuid: "asdr23454esdf4",
    },
    {
      name: "ID.jpeg",
      type: "jpeg",
      size: "50kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf5",
    },
    {
      name: "business_list.pdf",
      type: "pdf",
      size: "123kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf6",
    },
    {
      name: "vendor_list.docx",
      type: "docx",
      size: "250kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf7",
    },
    {
      name: "invoices.jpeg",
      type: "jpeg",
      size: "55kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf8",
    },
  ];

  const [checked, setChecked] = useState(0);
  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setChecked((prev) => prev + 1);
    } else {
      setChecked((prev) => prev - 1);
    }
  };

  const [text, setText] = useState("");
  const [selectedFont, setSelectedFont] = useState("Dancing Script");

  const fonts = [
    { value: "Dancing Script", label: "Dancing Script" },
    { value: "Satisfy", label: "Satisfy" },
    { value: "Pacifico", label: "Pacifico" },
    { value: "Great Vibes", label: "Great Vibes" },
    { value: "Alex Brush", label: "Alex Brush" },
    { value: "Allura", label: "Allura" },
  ];

  //   const handleTextChange = (text: string) => {
  //     console.log("Text changed:", text);
  //   };

  const handleItemSelect = (fileGuid: string) => {
    setSelectedItems([fileGuid]);
  };

  const handleFontChange = (font: string) => {
    setSelectedFont(font);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setText(newText);
    // if (onTextChange) {
    //   onTextChange(newText);
    // }
  };

  const toggleFontMenu = () => {
    const menu = document.getElementById("font-menu");
    if (menu) {
      menu.style.display = menu.style.display === "none" ? "block" : "none";
    }
  };
  const [selectedTab, setSelectedTab] = useState("Draw");
  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const saveSignature = () => {
    if (!text || !selectedFont) return;

    // Temporary canvas for measuring text width
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    if (!tempCtx) return;
    tempCtx.font = `32px ${selectedFont}`;

    // Measure text width and set a padding
    const textWidth = tempCtx.measureText(text).width;
    const padding = 20; // Additional padding on each side

    // Set canvas width dynamically based on text width
    const canvas = document.createElement("canvas");
    canvas.width = textWidth + padding * 2; // Add padding
    canvas.height = 60; // Set height as needed

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set background color
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set font and text properties
    ctx.font = `32px ${selectedFont}`;
    ctx.fillStyle = "black";
    ctx.textBaseline = "middle";

    // Draw the text, centered vertically and with horizontal padding
    const x = padding;
    const y = canvas.height / 2;
    ctx.fillText(text, x, y);

    // Convert to data URL and extract Base64 part
    const dataUrl = canvas.toDataURL("image/png");
    const base64Data = dataUrl.replace(/^data:image\/png;base64,/, "");

    // Optional: Trigger download
    const link = document.createElement("a");
    // link.download = `signature-${Date.now()}.png`;
    // link.href = dataUrl;
    // link.click();

    console.log(base64Data);

    // If using a callback to pass data URL
    // if (onSave) {
    //   onSave(dataUrl);
    // }
  };

  const handleSave = (signatureDataURL: string) => {
    console.log("Signature saved:", signatureDataURL);
    // Do something with the signature data URL
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
    } else {
      console.error("No file selected.");
    }
  };

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload_file");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  return (
    <div className="modal fade" tabIndex={-1} id="date_range">
      <div
        className="modal-lg modal-dialog modal-dialog-centered w-600px"
        // style={{ margin: "0 auto", marginTop: "10%" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            {" "}
            <h4 className="mb-0">Add your signature</h4>
          </div>
          <div className="modal-body p-10 pt-0 min-h-350px">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "Draw" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      onClick={() => handleTabClick("Draw")}
                    >
                      Draw
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "Type" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      onClick={() => handleTabClick("Type")}
                    >
                      Type
                    </a>
                  </li>
                  <li className="nav-item" key={3}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "Upload" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_3"
                      onClick={() => handleTabClick("Upload")}
                    >
                      Upload
                    </a>
                  </li>
                  <li className="nav-item" key={4}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "Saved" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_4"
                      onClick={() => handleTabClick("Saved")}
                    >
                      Saved
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="tab-content"
              id="myTabContent"
              style={{
                overflow: "scroll",
              }}
            >
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <>
                  <SignatureCanvas
                    width={533}
                    height={200}
                    onSave={handleSave}
                    penColor="#000000"
                    backgroundColor="#ffffff"
                  />
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="kt_tab_pane_2"
                role="tabpanel"
              >
                <div>
                  <div
                    ref={textContainerRef}
                    className="d-flex align-items-end border border-gray-300 rounded w-100 mb-2"
                    style={{
                      // width: "500px",
                      height: "200px",
                    }}
                  >
                    <input
                      type="text"
                      value={text}
                      onChange={handleTextChange}
                      placeholder="Your name"
                      className="form-control fs-5x px-5 py-3 border border-0"
                      style={{
                        fontFamily: selectedFont,
                        background: "transparent",
                      }}
                    ></input>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className="d-flex align-items-center justify-content-end cursor-pointer border-bottom border-dark"
                      onClick={() => handleFontChange("Dancing Script")}
                    >
                      <i className="bi bi-shuffle text-dark fw-bold fs-5 me-3"></i>
                      <p className="m-0 fs-5">Change font</p>
                    </div>
                    <button
                      className="btn btn-sm"
                      onClick={saveSignature}
                      disabled={!text}
                    >
                      Save Signature
                    </button>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <form
                  id="kt_modal_upload_file_form"
                  className="form"
                  //   onSubmit={editFormik.handleSubmit}
                >
                  <div className="d-flex flex-column scroll-y">
                    {file ? (
                      <div className="d-flex flex-column w-100 justify-content-center ">
                        <div className="d-flex justify-content-center">
                          <div
                            style={{
                              border: "1px dashed #888",
                              borderRadius: "8px",
                              padding: "20px",
                              width: "532px",
                              height: "200px",
                            }}
                          >
                            <div
                              className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                              style={{ width: "490px", height: "155px" }}
                            >
                              <div className="d-flex flex-row">
                                <p className="fw-bold">{file.size}&nbsp;</p>
                                <span>KB</span>
                              </div>
                              <div className="text-container my-2">
                                <p className="text-truncate">{file.name}</p>
                              </div>
                              <p
                                className="text-danger text-decoration-underline fw-bold cursor-pointer"
                                onClick={handleFileRemove}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            width: "532px",
                            height: "200px",
                          }}
                        >
                          <FileUploader
                            handleChange={handleAddFile}
                            name="file"
                            types={["jpg", "jpeg", "png", "svg", "webp"]}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px dashed #888",
                                borderRadius: "8px",
                                padding: "20px",
                                marginBottom: "10px",
                                width: "532px",
                                height: "200px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                size="4x"
                                style={{ color: "#888" }}
                              />
                              <p style={{ marginTop: "10px", color: "#888" }}>
                                Drag & drop image here, or click to select
                              </p>
                              <p style={{ marginTop: "10px", color: "#888" }}>
                                Max file size: 1 MB
                              </p>
                            </div>
                          </FileUploader>
                          {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="text-end pt-5">
                    <button
                      type="reset"
                      className="btn btn-light btn-sm me-3"
                      onClick={handleFileRemove}
                    >
                      Remove
                    </button>

                    <button
                      type="submit"
                      id="kt_modal_upload_file_submit"
                      className="btn btn-sm"
                      disabled={!file}
                    >
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
              <div
                className="tab-pane fade show"
                id="kt_tab_pane_4"
                role="tabpanel"
              >
                <div className=" max-h-200px overflow-y-scroll">
                  <div
                    className="row g-2"
                    style={{
                      maxHeight: "250px",
                      overflow: "scroll",
                    }}
                  >
                    {imgarray.map((item, index) => (
                      <div className="col-lg-4 col-md-4" key={index}>
                        <div
                          className={`border rounded p-4 h-100 cursor-pointer ${
                            selectedItems.includes(item.fileGuid)
                              ? "bg-light-primary border-primary"
                              : "bg-light bg-hover-secondary"
                          }`}
                          onClick={() => handleItemSelect(item.fileGuid)}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                          }}
                        >
                          <div className="text-center mb-3">
                            {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                            <img
                              src={folder}
                              alt=""
                              style={{ width: "100px", height: "80px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-end pt-5">
                    <button
                      type="reset"
                      className="btn btn-light btn-sm me-3"
                      onClick={handleFileRemove}
                    >
                      Delete
                    </button>

                    <button
                      type="submit"
                      id="kt_modal_upload_file_submit"
                      className="btn btn-sm"
                      //   disabled={!file}
                    >
                      <span className="indicator-label">Insert</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;
interface SignatureCanvasProps {
  width?: number;
  height?: number;
  onSave?: (signature: string) => void;
  penColor?: string;
  backgroundColor?: string;
}

const SignatureCanvas: React.FC<SignatureCanvasProps> = ({
  width = 400,
  height = 200,
  onSave,
  penColor = "#000000",
  backgroundColor = "rgb(255, 255, 255)",
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current, {
        backgroundColor,
        penColor,
      });
    }

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
      }
    };
  }, [backgroundColor, penColor]);

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const saveSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const dataURL = signaturePadRef.current.toDataURL();
      if (onSave) {
        onSave(dataURL);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        className="border border-gray-300 rounded"
      />
      <div className="d-flex gap-2 flex-end">
        <button onClick={clearSignature} className="btn btn-light btn-sm">
          Clear
        </button>
        <button
          onClick={saveSignature}
          className="btn btn-sm"
          disabled={signaturePadRef?.current?.isEmpty()}
        >
          Save
        </button>
      </div>
    </div>
  );
};
