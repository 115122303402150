import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { getUSD, getZAR } from "./api";
import Swal from "sweetalert2";
import { useFormCompletion } from "./Stepper";

interface RootObject {
  userId: string;
  tenantId: string;
  referenceId: string;
  referenceType: string;
  businessGuid: string;
  traceId: string;
  accounts: Account[];
  zarLimit: number;
  usdLimit: number;
}

export interface Account {
  accountNumber: string;
  currency: string;
  accountType: string;
  cif: string;
  swiftCode: string;
  balance: number;
  subAccountExist: boolean;
}

const USDAccount = () => {
  const { regUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RootObject | null>(null);
  const { markFormCompleted ,completedForms} = useFormCompletion();

  useEffect(() => {
     getUSD(regUser?.token ?? '', false).then((res) => {
      if (res?.data?.status?.statusCode === 0) {
        setData(res?.data?.message);
        markFormCompleted("Secondary-Account");
        console.log(completedForms);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, [regUser?.token]);

  const accountKeyMap: { [key in keyof Account]?: string } = {
    accountNumber: "Account Number",
    currency: "Currency",
    cif: "Customer ID",
    swiftCode: "SWIFT/BIC",
    balance: "Balance",
  };

  const handleSubmit = () => {
    navigate("/auth/pin");
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">
        Your USD account details are as follows
      </p>
      <div className="mt-10">
        <div className="p-10">
          <p className="fs-4 fw-bold">Account details</p>
          {loading ? (
            <div className="spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            data && (
              <div>
                {data.accounts.map((account, index) => (
                  <div key={index}>
                    {Object.entries(account)
                      .filter(([key]) => key in accountKeyMap)
                      .map(([key, value]) => (
                        <div key={key} className="mb-2">
                          <strong>
                            {accountKeyMap[key as keyof Account]}:
                          </strong>{" "}
                          {String(value)}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-10 p-10">
        <button className="btn w-100" onClick={handleSubmit}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default USDAccount;
