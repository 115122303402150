import { ChangeEvent, FC, useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown1 } from "../../../_metronic/partials";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import * as Yup from "yup";
import Swal from "sweetalert2";
import balnk from "../../../_metronic/assets/all-serviceIcons/file-download.svg";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import Authorize from "../../modules/auth/components/Authorize";
import { OverlayTrigger } from "react-bootstrap";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";
import gridImg from "../../../_metronic/assets/all-serviceIcons/grid-square.svg";
import listImg from "../../../_metronic/assets/all-serviceIcons/List_view.svg";
import pdf from "../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../_metronic/assets/all-serviceIcons/image-files.svg";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import SignatureModal from "./SignatureModal";

const Files: FC = () => {
  const [add, setAdd] = useState(false);
  const [list, setList] = useState(true);
  const [linkLoading, setLinkLoading] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [file, setFile] = useState<File | null>();
  const [fileurl, setUrl] = useState("");

  const editFormik = useFormik({
    initialValues: {
      userStatus: "",
    },
    validationSchema: Yup.object().shape({
      userStatus: Yup.string().required("Action is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("submit called");

      const modal = Modal.getInstance("#kt_edit_customer_modal");
      if (modal) {
        modal.hide();
      }
      const confirmationResult = await Swal.fire({
        icon: "question",
        text: "Are you sure you want to change the status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });

      if (confirmationResult.isConfirmed) {
        try {
          // await dispatch1(
          //   editCustomerStatus({
          //     url: values.userStatus, // Pass the dynamic URL
          //     tenantId: tenantId,
          //     // businessGuid: customer?.businessGuid || "",
          //     userId: userId,
          //   })
          // );
          //   await dispatch(fetchCustomer(fetchCustomersPayload as any) as any);
        } catch (error) {
          // Handle any errors that might occur during the asynchronous operations
          console.error("Error:", error);
        }
      } else if (confirmationResult.isDenied) {
        const modal = Modal.getInstance("#kt_edit_customer_modal");
        if (modal) {
          modal.show();
        }
      }
    },
    onReset: async (values, formikHelpers) => {
      // setFilter(false);
      // await dispatch(fetchCustomer(fetchCustomersPayload as any) as any);
    },
  });

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
    } else {
      console.error("No file selected.");
    }
  };

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload_file");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleSelect = () => {
    const modalElement = document.getElementById("kt_modal_select_file");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const location = useLocation();

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(array?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const array = [
    {
      name: "invoices.pdf",
      type: "pdf",
      size: "50kb",
      lastModified: "19 Aug 2024, 5:20 pm",
      fileGuid: "asdr23454esdf1",
    },
    {
      name: "payslips.pdf",
      type: "pdf",
      size: "112kb",
      lastModified: "22 Sep 2024, 10:30 am",
      fileGuid: "asdr23454esdf2",
    },
    {
      name: "AOA.docx",
      type: "docx",
      size: "79kb",
      lastModified: "24 Jun 2024, 11:30 am",
      fileGuid: "asdr23454esdf3",
    },
    {
      name: "License.jpeg",
      type: "jpeg",
      size: "33kb",
      lastModified: "21 Feb 2024, 2:40 pm",
      fileGuid: "asdr23454esdf4",
    },
    {
      name: "ID.jpeg",
      type: "jpeg",
      size: "50kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf5",
    },
    {
      name: "business_list.pdf",
      type: "pdf",
      size: "123kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf6",
    },
    {
      name: "vendor_list.docx",
      type: "docx",
      size: "250kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf7",
    },
    {
      name: "invoices.jpeg",
      type: "jpeg",
      size: "55kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf8",
    },
  ];

  const [checked, setChecked] = useState(0);
  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setChecked((prev) => prev + 1);
    } else {
      setChecked((prev) => prev - 1);
    }
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Handler for item selection
  const handleItemSelect = (fileGuid: string) => {
    setSelectedItems([fileGuid]);
    // setSelectedItems((prev) => {
    //   if (prev.includes(fileGuid)) {
    //     return prev.filter((id) => id !== fileGuid);
    //   } else {
    //     return [...prev, fileGuid];
    //   }
    // });
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Folders</h4>{" "}
      {/* <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-50px symbol-lg-50px symbol-fixed position-relative">
                <img src={balnk} style={{ objectFit: "cover" }} />
              </div>
            </div>

            <div className="flex-grow-1">
              <h2>File Manager</h2>

              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <p className="fw-bold">
                      {" "}
                      File Manager | 2.6 GB | 758 items
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex overflow-auto">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === "/crafted/pages/profile/overview" &&
                      "active")
                  }
                  to="#"
                >
                  File
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === "/crafted/pages/profile/projects" &&
                      "active")
                  }
                  to="#"
                >
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center  position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              // defaultValue={searchTerm}
              // onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            <div className="d-flex">
              <div
                className="d-flex border border-dark px-3 py-2 rounded-start-5 border-end-0 align-items-center"
                onClick={() => setList(true)}
              >
                {list && (
                  <i className="bi bi-check2 fs-2 fw-bold text-dark"></i>
                )}
                <img
                  src={listImg}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <div
                className="d-flex border border-dark px-3 py-2 rounded-end-5 align-items-center"
                onClick={() => setList(false)}
              >
                {!list && (
                  <i className="bi bi-check2 fs-2 fw-bold text-dark"></i>
                )}
                <img
                  src={gridImg}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            </div>

            {checked > 0 ? (
              <button
                className={`btn btn-danger fs-7 p-3 w-100px`}
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    text: "Are you sure you want to delete the selected folders?",
                  })
                }
                //   id="kt_modal_toggle_domestic"
                //   disabled={payAmount < 1}
              >
                Delete ({checked})
              </button>
            ) : (
              <>
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => setAdd(true)}
                  //   id="kt_modal_toggle_domestic"
                  //   disabled={payAmount < 1}
                >
                  New Folder
                </button>
                {/* </Authorize> */}
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => handleUpload()}
                >
                  Upload Files
                </button>
                {/* </Authorize> */}
              </>
            )}
          </div>
        </div>

        {array && array.length > 0 ? (
          !list ? (
            // Grid View
            <div className="ms-10 row g-2 gap-10">
              {array.map((item, index) => (
                <div className="col-lg-2 col-md-4" key={index}>
                  <div
                    className="border rounded p-4 h-100 bg-light bg-hover-secondary cursor-pointer"
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-start mb-3">
                      <div className="form-check form-check-custom form-check-sm">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          onClick={(e) => handleCheckClick(e)}
                        />
                      </div>
                      <div className="d-flex gap-2">
                        {/* Action buttons */}
                        <div className="dropdown">
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-link-45deg text-dark fs-3 p-0 m-0"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                {linkLoading ? (
                                  <div className="d-flex gap-3 align-items-center">
                                    {" "}
                                    <div
                                      className="spinner-border text-primary"
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                      }}
                                      role="status"
                                    ></div>
                                    <p className="m-0 fs-6">
                                      {" "}
                                      Generating share link ...
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-3">Share Link Generated</p>
                                    <input
                                      type="text"
                                      defaultValue={
                                        "https://path/to/file/or/folder/"
                                      }
                                      className="form-control form-control-sm mb-3"
                                    />
                                    <div className="d-flex gap-10">
                                      <p className="m-0">Read only.</p>
                                      <p
                                        className="text-primary m-0"
                                        onClick={() =>
                                          navigate("/documentRoom/settings")
                                        }
                                      >
                                        Change permissions
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                View
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => setEditingId(item.fileGuid)}
                              >
                                Rename
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleSelect()}
                              >
                                Add Signature
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                Download
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                type="button"
                                onClick={() =>
                                  Swal.fire({
                                    icon: "question",
                                    text: "Are you sure you want to delete documents?",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete",
                                  })
                                }
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="text-center mb-3">
                      {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                      <img
                        src={
                          item?.type === "pdf"
                            ? pdf
                            : item?.type === "jpeg"
                              ? jpeg
                              : docx
                        }
                        alt=""
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>

                    {editingId === item.fileGuid ? (
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Folder name"
                          defaultValue={item.name}
                        />
                        <div className="d-flex gap-1">
                          <i
                            className="bi bi-check-circle text-primary cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                          <i
                            className="bi bi-x-circle text-danger cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                        </div>
                      </div>
                    ) : (
                      <h5 className="text-center text-gray-800 mb-2">
                        {item.name} ({item.size})
                      </h5>
                    )}

                    <div className="text-center text-gray-600 small">
                      <div>{item.lastModified}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <table
              className="table align-middle border rounded table-row-dashed fs-6 g-5"
              style={{
                height: "440px",
                overflowY: "scroll",
              }}
            >
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6 ">
                  <th className="px-3">
                    {" "}
                    <label className="form-check form-check-custom form-check-sm fs-7">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked={selectAll}
                        // onClick={handleSelectAll}
                      />{" "}
                    </label>
                  </th>

                  <th className="min-w-200px p-5">Name</th>
                  <th className="min-w-100px p-5">Size</th>
                  <th className="min-w-100px p-5">Last Modified</th>
                  <th className="min-w-100px p-5">Action</th>
                </tr>
              </thead>

              <tbody>
                {add && (
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center gap-5">
                        <input
                          type="text"
                          className="form-control w-300px"
                          placeholder="Folder name"
                        />
                        <div className="d-flex gap-3">
                          <i className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"></i>
                          <i
                            className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                            onClick={() => setAdd(false)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {array && array.length > 0 ? (
                  array.map((item, index) => (
                    <tr
                      //   key={index}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                      }}
                    >
                      <td className="px-3">
                        <label className="form-check form-check-custom form-check-sm fs-7">
                          <input
                            className="form-check-input cursor-pointer border border-dark"
                            type="checkbox"
                            // checked={invoiceGuid.includes(item.invoiceGuid)}
                            // onClick={(e) =>
                            //   handleCheckClick(
                            //     e,
                            //     item?.invoiceGuid,
                            //     item.amount
                            //   )
                            // }
                          />{" "}
                        </label>
                      </td>

                      <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                        <div className=" d-flex flex-row gap-3 align-items-center">
                          {/* <i className="bi bi-folder2-open fs-2 text-dark"></i> */}
                          {editingId === item.fileGuid ? (
                            <div className="d-flex align-items-center gap-5">
                              <input
                                type="text"
                                className="form-control w-300px"
                                placeholder="Folder name"
                                defaultValue={item.name}
                              />
                              <div className="d-flex gap-3">
                                <i
                                  className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                                <i
                                  className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                              {item.name}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.size}</p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.lastModified}</p>
                      </td>
                      <td className="p-5 text-gray-600 p-2">
                        <div className="d-flex gap-5 align-items-center">
                          <div className="dropdown" style={{ width: "20px" }}>
                            <button
                              className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "25%",
                                backgroundColor: "#ebe9eb",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() =>
                                setTimeout(() => {
                                  setLinkLoading(false);
                                }, 1000)
                              }
                            >
                              <i className="bi bi-link-45deg text-dark fs-3 p-0 m-0"></i>
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => setToggle(true)}
                                >
                                  {linkLoading ? (
                                    <div className="d-flex gap-3 align-items-center">
                                      {" "}
                                      <div
                                        className="spinner-border text-primary"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                        role="status"
                                      ></div>
                                      <p className="m-0 fs-6">
                                        {" "}
                                        Generating share link ...
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="mb-3">
                                        Share Link Generated
                                      </p>
                                      <input
                                        type="text"
                                        defaultValue={
                                          "https://path/to/file/or/folder/"
                                        }
                                        className="form-control form-control-sm mb-3"
                                      />
                                      <div className="d-flex gap-10">
                                        <p className="m-0">Read only.</p>
                                        <p
                                          className="text-primary m-0"
                                          onClick={() =>
                                            navigate("/documentRoom/settings")
                                          }
                                        >
                                          Change permissions
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown" style={{ width: "20px" }}>
                            <button
                              className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "25%",
                                backgroundColor: "#ebe9eb",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => setToggle(true)}
                                >
                                  View
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => setEditingId(item.fileGuid)}
                                >
                                  Rename
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleOpenModal()}
                                >
                                  Self Sign
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => handleOpenModal()}
                                >
                                  Add bank seal & sign
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => setToggle(true)}
                                >
                                  Download
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item text-danger"
                                  type="button"
                                  onClick={() =>
                                    Swal.fire({
                                      icon: "question",
                                      text: "Are you sure you want to delete documents?",
                                      showCancelButton: true,
                                      confirmButtonText: "Yes, delete",
                                    })
                                  }
                                >
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )
        ) : (
          <div className="text-center py-5">
            <p className="text-gray-600">No Data Found</p>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(array?.length / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {array?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              //   onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="kt_modal_upload_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-between"
              id="kt_modal_upload_file_header"
            >
              <h2 className="fw-bold">Upload File</h2>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form
                id="kt_modal_upload_file_form"
                className="form"
                onSubmit={editFormik.handleSubmit}
              >
                <div className="d-flex flex-column scroll-y me-n7 pe-7">
                  {file ? (
                    <div className="d-flex flex-column w-100 justify-content-center ">
                      <div className="d-flex justify-content-center">
                        <div
                          style={{
                            border: "2px dashed #888",
                            borderRadius: "8px",
                            padding: "20px",
                            width: "340px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                            style={{ width: "300px" }}
                          >
                            <div className="d-flex flex-row">
                              <p className="fw-bold">{file.size}&nbsp;</p>
                              <span>KB</span>
                            </div>
                            <div className="text-container my-2">
                              <p
                                className="text-truncate"
                                style={{ maxWidth: "15rem" }}
                              >
                                {file.name}
                              </p>
                            </div>
                            <p
                              className="text-danger text-decoration-underline fw-bold cursor-pointer"
                              onClick={handleFileRemove}
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignSelf: "center",
                        }}
                      >
                        <FileUploader
                          handleChange={handleAddFile}
                          name="file"
                          types={["xlsx", "csv"]}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "2px dashed #888",
                              borderRadius: "8px",
                              padding: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCloudUploadAlt}
                              size="4x"
                              style={{ color: "#888" }}
                            />
                            <p style={{ marginTop: "10px", color: "#888" }}>
                              Drag & drop invoice here, or click to select
                              invoice
                            </p>
                            <p style={{ marginTop: "10px", color: "#888" }}>
                              Max file size is 1MB per file
                            </p>
                          </div>
                        </FileUploader>
                        {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                      </div>
                    </>
                  )}
                </div>
                <div className="text-center pt-15">
                  <button
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      editFormik.resetForm();
                    }}
                  >
                    Discard
                  </button>

                  <button
                    type="submit"
                    id="kt_modal_upload_file_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="kt_modal_select_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-between"
              id="kt_modal_select_file_header"
            >
              <h2 className="fw-bold">Select File</h2>
            </div>

            <div className="modal-body scroll-y mx-5 my-7">
              <div className="row g-2">
                {array.map((item, index) => (
                  <div className="col-lg-4 col-md-4" key={index}>
                    <div
                      className={`border rounded p-4 h-100 cursor-pointer ${
                        selectedItems.includes(item.fileGuid)
                          ? "bg-light-primary border-primary"
                          : "bg-light bg-hover-secondary"
                      }`}
                      onClick={() => handleItemSelect(item.fileGuid)}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                      }}
                    >
                      <div className="text-center mb-3">
                        {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                        <img
                          src={folder}
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>

                      <div className="text-center text-gray-600 small">
                        <div>{item.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center mt-5">
                <button
                  type="submit"
                  id="kt_modal_select_file_submit"
                  className="btn btn-primary align-self-center"
                >
                  <span className="indicator-label">Add</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignatureModal />
    </>
  );
};

export { Files };
