import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import AuthhLayout from "../modules/auth/AuthPage";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MenuTestPage } from "../pages/MenuTestPage";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import TwoFactorAuthentication from "../modules/auth/components/TwoFactorAuthentication";
import { Bars } from "react-loader-spinner";
import AccountDetails from "../pages/profile/AccountDetails";

import Vendor from "../pages/IncomingInvoice/vendorList";
import VendorDetails from "../pages/IncomingInvoice/vendorDetails";

import DashboardWrapper from "../pages/dashboard/DashboardWrapper";
import Transaction from "../pages/Transaction/Transaction";
import PayrollDashboard from "../pages/Payee/PayrollDashboard";
import BulkPaymentDashboard from "../pages/bulkPayment/bulkPaymentDashboard";
import LoanDashboard from "../pages/loan/LoanDashboard";
import PaymentLink from "../pages/PaymentLinks/PaymentLinkDashboard";
import Paybill from "../pages/paybill/Paybill";
import TableComponent from "../pages/TransferMoney/Table";
import Inventory from "../pages/Invoice/Inventory/Inventory";
import Customer from "../pages/Invoice/Customer/Customer";
import RuleSetting from "../pages/settings/RuleSettings";
import AddInvoice from "../pages/IncomingInvoice/AddInvoice";
import RuleDashboard from "../pages/settings/RuleDashboard";
import { Folder } from "../pages/documentRoom/Folder";
import Settings from "../pages/documentRoom/Settings";
import { Files } from "../pages/documentRoom/Files";
import { Sign } from "../pages/documentRoom/Sign";
import OnDemandDoc from "../pages/documentRoom/OnDemandDoc";

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  // const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  // const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  // const UsersPage = lazy(
  //   () => import("../modules/apps/user-management/UsersPage")
  // );

  const SubAccount = lazy(() => import("../pages/SubAccounts/SubAccount"));
  const SubAccountListing = lazy(
    () => import("../pages/SubAccounts/SubAccountListing")
  );

  const PayrollSetttings = lazy(
    () => import("../pages/Payee/PayrollSettings/PayrollSettings")
  );
  const TaxCalculator = lazy(
    () => import("../pages/Payee/PayrollStepper/TaxCalculator")
  );
  const PaySalary = lazy(() => import("../pages/Payee/PaySalary/PaySalary"));
  const Invoice = lazy(() => import("../pages/Invoice/Invoice"));

  const InvoiceSettings = lazy(
    () => import("../pages/Invoice/InvoiceSettings/InvoiceSettings")
  );
  const CreateInvoice = lazy(
    () => import("../pages/Invoice/CreateInvoice/CreateInvoice")
  );
  const PayeeManagement = lazy(
    () => import("../pages/PayeeManagement/PayeeManagement")
  );

  const Statistics = lazy(() => import("../pages/statistics/Statistics"));
  const EditProfile = lazy(() => import("../pages/profile/EditProfile"));
  const Security = lazy(() => import("../pages/profile/Security"));
  const QRDashboard = lazy(() => import("../pages/qr/QRDashboard"));
  const Faq = lazy(() => import("../pages/faq/Faq"));

  const CreateBulkPayments = lazy(
    () => import("../pages/bulkPayment/createBulkPayments/create-bulkpayments")
  );
  const MyCard = lazy(() => import("../pages/MyCard/MyCard"));
  const SubAccountTransaction = lazy(
    () => import("../pages/SubAccounts/SubAccountTransaction/SubAccTransaction")
  );
  const QrListing = lazy(() => import("../pages/qr/QrListing"));
  const Vertical = lazy(() => import("../pages/statements/Statements"));
  const VideoLibrary = lazy(() => import("../pages/VideoLibrary/VideoLibrary"));
  const VideoListing = lazy(() => import("../pages/VideoLibrary/VideoListing"));
  const CommunicationWrapper = lazy(
    () => import("../pages/communicationCenter/communicationcenter")
  );
  const ServiceTicket = lazy(
    () => import("../pages/ServiceTicket/ServiceTicket")
  );
  const ReplyPage = lazy(() => import("../pages/ServiceTicket/ReplyPage"));
  const Subscription = lazy(() => import("../pages/Subscription/Subscription"));
  const TeamListPage = lazy(() => import("../pages/TeamBanking/TeamListing"));
  const ViewUser = lazy(() => import("../pages/TeamBanking/ViewUser"));
  const Roles = lazy(() => import("../pages/TeamBanking/Roles"));
  const Approvals = lazy(() => import("../pages/TeamBanking/Approvals"));
  const ViewRole = lazy(() => import("../pages/TeamBanking/ViewRole"));
  const Initiations = lazy(() => import("../pages/TeamBanking/Initiations"));
  const CardlessPage = lazy(
    () => import("../pages/cardlessWithdrawl/cardlessDash")
  );
  const CompanyDetails = lazy(() => import("../pages/profile/CompanyDetails"));
  const PartnerDetails = lazy(() => import("../pages/profile/PartnerDetails"));
  const Quotations = lazy(() => import("../pages/CreateQuotation/Quotation"));
  const CreateQuo = lazy(
    () => import("../pages/CreateQuotation/CreateQuotation")
  );
  const EditQuo = lazy(() => import("../pages/CreateQuotation/EditQuotation"));
  const QuotationSettings = lazy(
    () => import("../pages/CreateQuotation/QuotationSettings")
  );
  const Recon1 = lazy(() => import("../pages/reconcilation/recon1"));
  const Reconcilation = lazy(
    () => import("../pages/reconcilation/reconcilation")
  );
  const DomesticVendorDashboard = lazy(
    () => import("../pages/IncomingInvoice/IncomingTable")
  );
  const InternationalVendorDashboard = lazy(
    () => import("../pages/IncomingInvoice/InternationalVendorTable")
  );

  return (
    <Routes>
      <Route element={<AuthhLayout />}>
        <Route path="auth/*" element={<Navigate to="/two-factor" />} />
        <Route path="two-factor" element={<TwoFactorAuthentication />} />
      </Route>

      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}

        <Route path="settings" element={<RuleSetting />} />
        <Route
          path="dashboard"
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="money-movement/transaction-dashboard"
          element={
            <SuspensedView>
              <Transaction />
            </SuspensedView>
          }
        />
        {/* <Route
          path="editprofile"
          element={
            <SuspensedView>
              <EditProfile />
            </SuspensedView>
          }
        /> */}
        <Route
          path="profile/details"
          element={
            <SuspensedView>
              <EditProfile />
            </SuspensedView>
          }
        />

        <Route
          path="profile/companyDetails"
          element={
            <SuspensedView>
              <CompanyDetails />
            </SuspensedView>
          }
        />
        <Route
          path="profile/partnerDetails"
          element={
            <SuspensedView>
              <PartnerDetails />
            </SuspensedView>
          }
        />

        <Route
          path="security"
          element={
            <SuspensedView>
              <Security />
            </SuspensedView>
          }
        />
        <Route
          path="subscription"
          element={
            <SuspensedView>
              <Subscription />
            </SuspensedView>
          }
        />
        <Route
          path="subaccounts"
          element={
            <SuspensedView>
              <SubAccount />
            </SuspensedView>
          }
        />
        <Route
          path="subaccount-listing"
          element={
            <SuspensedView>
              <SubAccountListing />
            </SuspensedView>
          }
        />
        <Route
          path="subaccount-transactions"
          element={
            <SuspensedView>
              <SubAccountTransaction />
            </SuspensedView>
          }
        />
        <Route
          path="/payrollemp/payroll-dashboard"
          element={
            <SuspensedView>
              <PayrollDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="payroll-settings"
          element={
            <SuspensedView>
              <PayrollSetttings />
            </SuspensedView>
          }
        />
        <Route
          path="payroll-paysalary"
          element={
            <SuspensedView>
              <PaySalary />
            </SuspensedView>
          }
        />
        <Route
          path="money-movement/payee-management"
          element={
            <SuspensedView>
              <PayeeManagement />
            </SuspensedView>
          }
        />
        <Route
          path="/bulk-payment"
          element={
            <SuspensedView>
              <BulkPaymentDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="money-movement/create-bulkpayments"
          element={
            <SuspensedView>
              <CreateBulkPayments />
            </SuspensedView>
          }
        />
        <Route
          path="money-movement/cardless-withdrawal"
          element={
            <SuspensedView>
              <CardlessPage />
            </SuspensedView>
          }
        />

        <Route
          path="my-card"
          element={
            <SuspensedView>
              <MyCard />
            </SuspensedView>
          }
        />
        <Route
          path="loan"
          element={
            <SuspensedView>
              <LoanDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="statements"
          element={
            <SuspensedView>
              <Vertical />
            </SuspensedView>
          }
        />
        <Route
          path="invoice/invoice-dashboard"
          element={
            <SuspensedView>
              <Invoice />
            </SuspensedView>
          }
        />
        <Route
          path="invoice/inventory"
          element={
            <SuspensedView>
              <Inventory />
            </SuspensedView>
          }
        />
        <Route
          path="invoice/customers"
          element={
            <SuspensedView>
              <Customer />
            </SuspensedView>
          }
        />
        <Route
          path="invoice/settings"
          element={
            <SuspensedView>
              <InvoiceSettings />
            </SuspensedView>
          }
        />
        <Route
          path="invoice/create-invoice"
          element={
            <SuspensedView>
              <CreateInvoice />
            </SuspensedView>
          }
        />
        <Route
          path="tax-calculator"
          element={
            <SuspensedView>
              <TaxCalculator />
            </SuspensedView>
          }
        />
        <Route
          path="payment-link/payment-dashboard"
          element={
            <SuspensedView>
              <PaymentLink />
            </SuspensedView>
          }
        />

        <Route
          path="qr/qr-dashboard"
          element={
            <SuspensedView>
              <QRDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="qr/qr-listing"
          element={
            <SuspensedView>
              <QrListing />
            </SuspensedView>
          }
        />
        <Route
          path="money-movement/transfer-money"
          element={
            <SuspensedView>
              <TableComponent />
            </SuspensedView>
          }
        />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route
          path="paybill"
          element={
            <SuspensedView>
              <Paybill />
            </SuspensedView>
          }
        />
        <Route
          path="analytics"
          element={
            <SuspensedView>
              <Statistics />
            </SuspensedView>
          }
        />
        <Route
          path="communication-center"
          element={
            <SuspensedView>
              <CommunicationWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="/helpdesk/faq"
          element={
            <SuspensedView>
              <Faq />
            </SuspensedView>
          }
        />
        <Route
          path="/helpdesk/video-library"
          element={
            <SuspensedView>
              <VideoLibrary />
            </SuspensedView>
          }
        />
        <Route
          path="/helpdesk/video-listing"
          element={
            <SuspensedView>
              <VideoListing />
            </SuspensedView>
          }
        />
        <Route
          path="/helpdesk/service-ticket"
          element={
            <SuspensedView>
              <ServiceTicket />
            </SuspensedView>
          }
        />
        <Route
          path="/helpdesk/service-ticket/reply"
          element={
            <SuspensedView>
              <ReplyPage />
            </SuspensedView>
          }
        />
        <Route
          path="/team-banking/team-listing"
          element={
            <SuspensedView>
              <TeamListPage />
            </SuspensedView>
          }
        />
        <Route
          path="/team-banking/team-listing/viewuser"
          element={
            <SuspensedView>
              <ViewUser />
            </SuspensedView>
          }
        />
        <Route
          path="/team-banking/roles"
          element={
            <SuspensedView>
              <Roles />
            </SuspensedView>
          }
        />

        <Route
          path="/team-banking/roles/view-role"
          element={
            <SuspensedView>
              <ViewRole />
            </SuspensedView>
          }
        />
        <Route
          path="/team-banking/approvals"
          element={
            <SuspensedView>
              <Approvals />
            </SuspensedView>
          }
        />
        <Route
          path="/team-banking/initiations"
          element={
            <SuspensedView>
              <Initiations />
            </SuspensedView>
          }
        />
        <Route
          path="/reconcilation"
          element={
            <SuspensedView>
              <Reconcilation />
            </SuspensedView>
          }
        />
        <Route
          path="/recon-1"
          element={
            <SuspensedView>
              <Recon1 />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/domesticVendor"
          element={
            <SuspensedView>
              <DomesticVendorDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/internationalVendor"
          element={
            <SuspensedView>
              <InternationalVendorDashboard />
            </SuspensedView>
          }
        />

        <Route
          path="/invoice/vendorList"
          element={
            <SuspensedView>
              <Vendor />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/vendorDetails"
          element={
            <SuspensedView>
              <VendorDetails />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/addInvoice"
          element={
            <SuspensedView>
              <AddInvoice />
            </SuspensedView>
          }
        />

        <Route
          path="/invoice/quotations"
          element={
            <SuspensedView>
              <Quotations />
            </SuspensedView>
          }
        />

        <Route
          path="/invoice/create-quotation"
          element={
            <SuspensedView>
              <CreateQuo />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/edit-quotation"
          element={
            <SuspensedView>
              <EditQuo />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/quotation-settings"
          element={
            <SuspensedView>
              <QuotationSettings />
            </SuspensedView>
          }
        />
        <Route
          path="profile/accountDetails"
          element={
            <SuspensedView>
              <AccountDetails />
            </SuspensedView>
          }
        />
        <Route
          path="ruleSettings/Rule"
          element={
            <SuspensedView>
              <RuleDashboard />
            </SuspensedView>
          }
        />

        <Route
          path="documentRoom/folder"
          element={
            <SuspensedView>
              <Folder />
            </SuspensedView>
          }
        />

        <Route
          path="documentRoom/files"
          element={
            <SuspensedView>
              <Files />
            </SuspensedView>
          }
        />
        <Route
          path="documentRoom/signs"
          element={
            <SuspensedView>
              <Sign />
            </SuspensedView>
          }
        />
        <Route
          path="documentRoom/onDemandDoc"
          element={
            <SuspensedView>
              <OnDemandDoc />
            </SuspensedView>
          }
        />
        <Route
          path="documentRoom/settings"
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  const FullScreenLoader = () => (
    <div
      className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0  bg-white bg-opacity-75"
      style={{ zIndex: 1000, bottom: "40%" }}
    >
      <Bars color={baseColor} height={80} width={80} />
    </div>
  );

  return (
    <Suspense
      fallback={
        <>
          <TopBarProgress />
          <div className="position-relative" style={{ minHeight: "100vh" }}>
            <FullScreenLoader />
          </div>
        </>
      }
    >
      <div className="position-relative" style={{ minHeight: "100vh" }}>
        {children}
      </div>
    </Suspense>
  );
};

export { PrivateRoutes };
