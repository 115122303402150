import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useAuth } from "../../core/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createPin, verifyOtp } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";

const Pin = () => {
  const [activeInputCreate, setActiveInputCreate] = useState(0); // State for "Create" section
  const [activeInputConfirm, setActiveInputConfirm] = useState(0); // State for "Confirm" section
  const [loading, setLoading] = useState(false);
  const { markFormCompleted } = useFormCompletion();
  const otpInputRefs = useRef<(HTMLInputElement | null)[]>([
    null,
    null,
    null,
    null,
  ]);
  const confirmOtpInputRefs = useRef<(HTMLInputElement | null)[]>([
    null,
    null,
    null,
    null,
  ]);
  const { regUser } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      otp: ["", "", "", ""], // Changed to 4 digits
      confirmOtp: ["", "", "", ""], // Changed to 4 digits
      status: "",
    },
    validationSchema: Yup.object().shape({
      otp: Yup.array()
        .of(
          Yup.string().matches(/^\d$/, "Each OTP field must be a single digit")
        )
        .length(4, "PIN must be exactly 4 digits")
        .test(
          "is-full",
          "PIN must be exactly 4 digits",
          (value) => value?.join("").length === 4
        ),
      confirmOtp: Yup.array()
        .of(
          Yup.string().matches(/^\d$/, "Each OTP field must be a single digit")
        )
        .length(4, "Confirmation PIN must be exactly 4 digits")
        .test(
          "is-full",
          "Confirmation PIN must be exactly 4 digits",
          (value) => value?.join("").length === 4
        )
        .test("match", "PINs do not match", function (value) {
          return value?.join("") === this.parent.otp.join("");
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const data = await createPin(
          values?.otp?.join(""),
          regUser?.token ?? ""
        );
        if (data?.data?.status?.statusCode === 0) {
          setLoading(false);
          navigate("/auth/plans");
          resetForm();
          setActiveInputCreate(0);
          setActiveInputConfirm(0);
          markFormCompleted("Mpin");
        } else {
          formik.setFieldValue(
            "status",
            data?.data?.status?.messageDescription
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
      console.log(values);
    },
  });

  const handleChange = (
    value: string,
    index: number,
    isConfirm: boolean = false
  ): void => {
    const fieldName = isConfirm ? "confirmOtp" : "otp";
    const fieldArray = [...formik.values[fieldName]];

    if (/^\d*$/.test(value) && fieldArray.length === 4) {
      fieldArray[index] = value;
      formik.setFieldValue(fieldName, fieldArray);

      if (value && index < fieldArray.length - 1) {
        if (isConfirm) {
          setActiveInputConfirm(index + 1); // Update active input for "Confirm" section
        } else {
          setActiveInputCreate(index + 1); // Update active input for "Create" section
        }
      }
    }
  };
  

  useEffect(() => {
    otpInputRefs.current[activeInputCreate]?.focus();
  }, [activeInputCreate]);

  useEffect(() => {
    confirmOtpInputRefs.current[activeInputConfirm]?.focus();
  }, [activeInputConfirm]);

  useEffect(() => {
    otpInputRefs.current[0]?.focus();
  }, []);

  const handleKeyDown = (
    e: React.KeyboardEvent,
    index: number,
    isConfirm: boolean = false
  ): void => {
    if (e.key === "Backspace") {
      const fieldName = isConfirm ? "confirmOtp" : "otp";
      const fieldArray = [...formik.values[fieldName]];

      if (fieldArray[index] !== "") {
        fieldArray[index] = "";
        formik.setFieldValue(fieldName, fieldArray);
      } else if (index > 0) {
        if (isConfirm) {
          setActiveInputConfirm(index - 1); // Update active input for "Confirm" section
        } else {
          setActiveInputCreate(index - 1); // Update active input for "Create" section
        }
        fieldArray[index - 1] = "";
        formik.setFieldValue(fieldName, fieldArray);
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-20">
      <div>
        {formik.values.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik.values.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div>
            <label className="form-label">Create your 4-digit MPIN</label>
            <div className="d-flex justify-content-center">
              <OTPInput
                value={formik.values.otp.join("")}
                onChange={(value: string) => {
                  value
                    .split("")
                    .forEach((char, index) => handleChange(char, index));
                }}
                numInputs={4} // Changed to 4 inputs
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    value={formik.values.otp[index]}
                    ref={(el) => (otpInputRefs.current[index] = el)}
                    onChange={(e) => {
                      handleChange(e.target.value, index);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e, index);
                    }}
                    type="password"
                    autoFocus={true}
                    disabled={index !== activeInputCreate}
                    onFocus={() => setActiveInputCreate(index)}
                    className="text-center rounded mt-3 border border-1 border-dark ms-5"
                    style={{ height: "50px", width: "50px" }}
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-20">
            <label className="form-label">Confirm your 4-digit MPIN</label>
            <div className="d-flex justify-content-center">
              <OTPInput
                value={formik.values.confirmOtp.join("")}
                onChange={(value: string) => {
                  value
                    .split("")
                    .forEach((char, index) => handleChange(char, index, true));
                }}
                numInputs={4} // Changed to 4 inputs
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    value={formik.values.confirmOtp[index]}
                    ref={(el) => (confirmOtpInputRefs.current[index] = el)} // Adjusted ref to confirmOtpInputRefs
                    onChange={(e) => {
                      handleChange(e.target.value, index, true);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e, index, true);
                    }}
                    type="password"
                    autoFocus={true}
                    disabled={index !== activeInputConfirm}
                    onFocus={() => setActiveInputConfirm(index)}
                    className="text-center rounded mt-3 border border-1 border-dark ms-5"
                    style={{ height: "50px", width: "50px" }}
                  />
                )}
              />
            </div>
          </div>

          {formik.touched.confirmOtp && formik.errors.confirmOtp && (
            <div className="text-danger mt-3">
              {formik.errors.confirmOtp.toString()}
            </div>
          )}
          <div className="mt-20">
            <button type="submit" className="btn w-100">
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="" role="status" aria-hidden="true"></span>
                </div>
              ) : (
                <div>Verify</div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pin;
